/* -------------------------------- accordion ------------------------------- */

.accordion-button {
  font-weight: $font-weight-bold;
}

.accordion-item {
  border-bottom: 1px solid var(--#{$prefix}border-color);
  padding: map-get($spacers, 2);
}

.accordion-button-arrow-icon {
  --ctc-accordion-btn-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIzNTQwXzQ4NjIxKSI+CjxwYXRoIGQ9Ik0zLjYwMDUyIDUuODY5NTNDMy45MDA1MiA1Ljg2OTUzIDQuMTY3MTkgNS45Njk1MyA0LjM2NzE5IDYuMTY5NTNMMTAuMDAwNSAxMS44MDI5TDE1LjY2NzIgNi4xNjk1M0MxNi4wNjcyIDUuNzM2MiAxNi43NjcyIDUuNzM2MiAxNy4xNjcyIDYuMTY5NTNDMTcuNjAwNSA2LjU2OTUzIDE3LjYwMDUgNy4yNjk1MyAxNy4xNjcyIDcuNjY5NTNMMTAuNzY3MiAxNC4wNjk1QzEwLjM2NzIgMTQuNTAyOSA5LjY2NzE5IDE0LjUwMjkgOS4yNjcxOSAxNC4wNjk1TDIuODY3MTkgNy42Njk1M0MyLjQzMzg2IDcuMjY5NTMgMi40MzM4NiA2LjU2OTUzIDIuODY3MTkgNi4xNjk1M0MzLjA2NzE5IDUuOTY5NTMgMy4zMzM4NiA1Ljg2OTUzIDMuNjAwNTIgNS44Njk1M1oiIGZpbGw9IiMzODc0RkYiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMzU0MF80ODYyMSI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSI5LjMzMzMzIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDE4IDE0LjY2OCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
  --ctc-accordion-btn-active-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIzNTQwXzQ4NjIxKSI+CjxwYXRoIGQ9Ik0zLjYwMDUyIDUuODY5NTNDMy45MDA1MiA1Ljg2OTUzIDQuMTY3MTkgNS45Njk1MyA0LjM2NzE5IDYuMTY5NTNMMTAuMDAwNSAxMS44MDI5TDE1LjY2NzIgNi4xNjk1M0MxNi4wNjcyIDUuNzM2MiAxNi43NjcyIDUuNzM2MiAxNy4xNjcyIDYuMTY5NTNDMTcuNjAwNSA2LjU2OTUzIDE3LjYwMDUgNy4yNjk1MyAxNy4xNjcyIDcuNjY5NTNMMTAuNzY3MiAxNC4wNjk1QzEwLjM2NzIgMTQuNTAyOSA5LjY2NzE5IDE0LjUwMjkgOS4yNjcxOSAxNC4wNjk1TDIuODY3MTkgNy42Njk1M0MyLjQzMzg2IDcuMjY5NTMgMi40MzM4NiA2LjU2OTUzIDIuODY3MTkgNi4xNjk1M0MzLjA2NzE5IDUuOTY5NTMgMy4zMzM4NiA1Ljg2OTUzIDMuNjAwNTIgNS44Njk1M1oiIGZpbGw9IiMzODc0RkYiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMzU0MF80ODYyMSI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSI5LjMzMzMzIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDE4IDE0LjY2OCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
  [data-bs-theme='dark'] & {
    .accordion-button{
      &::after{
        --ctc-accordion-btn-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIzNTQzXzQ4NjI0KSI+CjxwYXRoIGQ9Ik0zLjYwMDUyIDUuODY5NTNDMy45MDA1MiA1Ljg2OTUzIDQuMTY3MTkgNS45Njk1MyA0LjM2NzE5IDYuMTY5NTNMMTAuMDAwNSAxMS44MDI5TDE1LjY2NzIgNi4xNjk1M0MxNi4wNjcyIDUuNzM2MiAxNi43NjcyIDUuNzM2MiAxNy4xNjcyIDYuMTY5NTNDMTcuNjAwNSA2LjU2OTUzIDE3LjYwMDUgNy4yNjk1MyAxNy4xNjcyIDcuNjY5NTNMMTAuNzY3MiAxNC4wNjk1QzEwLjM2NzIgMTQuNTAyOSA5LjY2NzE5IDE0LjUwMjkgOS4yNjcxOSAxNC4wNjk1TDIuODY3MTkgNy42Njk1M0MyLjQzMzg2IDcuMjY5NTMgMi40MzM4NiA2LjU2OTUzIDIuODY3MTkgNi4xNjk1M0MzLjA2NzE5IDUuOTY5NTMgMy4zMzM4NiA1Ljg2OTUzIDMuNjAwNTIgNS44Njk1M1oiIGZpbGw9IiM4NUE5RkYiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMzU0M180ODYyNCI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSI5LjMzMzMzIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDE4IDE0LjY2OCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
        --ctc-accordion-btn-active-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIzNTQzXzQ4NjI0KSI+CjxwYXRoIGQ9Ik0zLjYwMDUyIDUuODY5NTNDMy45MDA1MiA1Ljg2OTUzIDQuMTY3MTkgNS45Njk1MyA0LjM2NzE5IDYuMTY5NTNMMTAuMDAwNSAxMS44MDI5TDE1LjY2NzIgNi4xNjk1M0MxNi4wNjcyIDUuNzM2MiAxNi43NjcyIDUuNzM2MiAxNy4xNjcyIDYuMTY5NTNDMTcuNjAwNSA2LjU2OTUzIDE3LjYwMDUgNy4yNjk1MyAxNy4xNjcyIDcuNjY5NTNMMTAuNzY3MiAxNC4wNjk1QzEwLjM2NzIgMTQuNTAyOSA5LjY2NzE5IDE0LjUwMjkgOS4yNjcxOSAxNC4wNjk1TDIuODY3MTkgNy42Njk1M0MyLjQzMzg2IDcuMjY5NTMgMi40MzM4NiA2LjU2OTUzIDIuODY3MTkgNi4xNjk1M0MzLjA2NzE5IDUuOTY5NTMgMy4zMzM4NiA1Ljg2OTUzIDMuNjAwNTIgNS44Njk1M1oiIGZpbGw9IiM4NUE5RkYiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMzU0M180ODYyNCI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSI5LjMzMzMzIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDE4IDE0LjY2OCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }
  }
}
