/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: $zindex-sticky;
  top: $navbar-top-height + 1;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed var(--#{$prefix}primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none {
  @include hover-focus {
    text-decoration: none;
  }
}
.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}
.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

[data-dismiss='dropdown'],
[data-bs-offset-top],
[data-bs-toggle='collapse'],
[data-bs-toggle='tooltip'],
[data-bs-toggle='popover'] {
  * {
    pointer-events: none;
  }
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;
  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/*-----------------------------------------------
|   Vertical Line (used in kanban header)
-----------------------------------------------*/
.vertical-line {
  &:after {
    position: absolute;
    content: '';
    height: 75%;
    width: 1px;
    background: var(--#{$prefix}tertiary-bg);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.vertical-line-400 {
    &:after {
      background-color: var(--#{$prefix}quaternary-bg);
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */

.transition-base {
  transition: $transition-base;
}
.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

// used in chat
.min-w-0 {
  min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                Terms sidebar                               */
/* -------------------------------------------------------------------------- */

.terms-sidebar {
  .nav-link {
    color: var(--#{$prefix}quaternary-color);
    font-weight: $font-weight-medium;
    font-family: var(--#{$prefix}font-sans-serif);
    &.active {
      color: var(--#{$prefix}primary);
    }
  }
}

.divider-content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);
  background-color: var(--#{$prefix}body-bg);
  font-family: var(--#{$prefix}font-sans-serif);
  font-size: map-get($font-sizes, 9);
  color: rgba(var(--#{$prefix}tertiary-color-rgb), 0.85);
  white-space: nowrap;
  font-weight: $font-weight-bold;
  .bg-auth-card & {
    [data-bs-theme='dark'] & {
      background-color: $body-bg-dark !important;
    }
  }
}

.btn-check[data-theme-control]:checked + label {
  display: none;
}
/* -------------------------------------------------------------------------- */
/*                                    Height                                  */
/* -------------------------------------------------------------------------- */

// html {
//   position: relative;
// }
.content {
  min-height: 100vh;
  // min-height: calc(100vh - $navbar-top-height);
  padding: $content-padding-top map-get($spacers, 4) $content-padding-bottom
    map-get($spacers, 4);
  position: relative;
  padding-bottom: $footer-height + 2;
  @include media-breakpoint-up(sm) {
    padding-bottom: $footer-height;
  }
}
.footer {
  bottom: 0;
  width: 100%;
  height: $footer-height + 2;
  border-top: 1px solid $navbar-footer-border-color;
  padding: 0 map-get($spacers, 4);
  left: 0;
  @include media-breakpoint-up(sm) {
    height: $footer-height;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 map-get($spacers, 6);
  }
}

.bullet-item {
  height: 0.5rem;
  width: 1rem;
  border-radius: 2px;
}

/* -------------------------------------------------------------------------- */
/*                                    Padding                                  */
/* -------------------------------------------------------------------------- */
.pt-navbar {
  padding-top: var(--#{$prefix}navbar-top-height);
}

/* -------------------------------------------------------------------------- */
/*                                    Logo                                    */
/* -------------------------------------------------------------------------- */

.logo-text {
  font-size: 1.63625rem;
  color: var(--#{$prefix}logo-text);
  font-weight: $font-weight-semibold;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}

.notification-card {
  &.unread {
    background-color: var(--#{$prefix}body-highlight-bg);
  }
}
.notification-card:hover {
  background-color: var(--#{$prefix}secondary-bg) !important;
}
.notification-dropdown {
  .dropdown-toggle {
    &:hover {
      border-color: var(--#{$prefix}border-color);
      background: transparent;
    }
  }
  .dropdown-menu {
    right: 0;
    left: unset !important;
  }
}

.square-icon-btn-sm {
  height: 1.875rem;
  width: 1.875rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square-icon-btn {
  height: 2.375rem;
  width: 2.375rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Pricing Grid
.pricing--hover {
  @include hover-focus {
    .warning-boxshadow {
      box-shadow: 0px 0px 0px 4px rgba(229, 120, 11, 0.2);
      border-radius: 0.5rem;
      outline: 0;
    }
  }
}
.card-form-check-input {
  // + {
  //   label {
  //     border: 1px solid transparent;
  //     border-radius: 0.5rem;
  //   }
  // }
  &:checked {
    + {
      div {
        .card {
          border: 1px solid var(--#{$prefix}primary-light);
          box-shadow: 0px 0px 0px 4px rgba(56, 116, 255, 0.2);
          .radio-circle {
            border: 5px solid var(--#{$prefix}primary);
          }
        }
      }
    }
  }
  &.pricing-plan-recommended {
    &:checked {
      + {
        div {
          .card {
            border: 1px solid var(--#{$prefix}warning-light);
            box-shadow: 0px 0px 0px 4px rgba(229, 120, 11, 0.2);
          }
        }
      }
    }
  }
}

[data-bs-theme='dark'] {
  .footer-bg {
    opacity: 0.6;
  }
}

.text-illustration-underline {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
}

.bg-linear-gradient {
  background: linear-gradient(
    95.11deg,
    #f24e1e -21.8%,
    #ff7262 -2.23%,
    #a259ff 46.16%,
    #1abcfe 79.11%,
    #0acf83 116.18%
  );
}

// ---- hover bg transparent
.hover-bg-transparent {
  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}

/* ------------------------------ font-awesome ------------------------------ */
.fa-ul {
  [dir='rtl'] & {
    --fa-li-margin: -0.5em !important;
    &.pricing {
      margin-left: var(--fa-li-margin, 2.5em) !important;
    }
  }
  margin-left: var(--fa-li-margin, 2.5em);
  .fa-li {
    left: calc(var(--fa-li-width, 2em) * -1) !important;
  }
}
.page-title-sticky-top {
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: calc(var(--ctc-navbar-top-height) + 3.725rem);
  }
}

.pricing-business-plus {
  [data-bs-theme='dark'] & {
    background-color: rgba(var(--#{$prefix}warning-rgb), 0.08) !important;
    border: 1px solid rgba(var(--#{$prefix}warning-rgb), 0.2);
  }
}

// echarts example
.echart-quartet-scatter-chart-example {
  min-height: 31.25rem;

  @include media-breakpoint-down(md) {
    min-height: 65.625rem;
  }
}
.echart-radar-customized-chart-example {
  height: 22rem;

  @include media-breakpoint-down(xl) {
    height: 45rem;
  }
}
.echart-radar-multiple-chart-example {
  height: 21rem;

  @include media-breakpoint-down(xxl) {
    height: 35rem;
  }
  @include media-breakpoint-down(lg) {
    height: 50rem;
  }
}

.faq-list {
  li {
    .fa-circle {
      font-size: 6px;
      margin-right: 1.25rem;
      margin-top: 6px;
      color: var(--#{$prefix}quaternary-bg);
    }
  }
}
.faq-title-box {
  top: map-get($spacers, 5);
  width: 90%;
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(xl) {
    width: 55%;
  }
}

/*-----------------------------------------------
|   fontawesome stack icon border color
-----------------------------------------------*/
.stack-circle path {
  stroke: var(--#{$prefix}body-bg);
  stroke-width: 60;
}

/*-----------------------------------------------
|   action dropdown btn
-----------------------------------------------*/
.action-btn {
  min-height: 32px;
  min-width: 45px;
}

/*-----------------------------------------------
|   additional class
-----------------------------------------------*/
.w-max-content {
  width: max-content !important;
}
.w-min-content {
  width: min-content !important;
}

/*-----------------------------------------------
|   widgets scrollspy
-----------------------------------------------*/
.widgets-scrollspy-nav {
  position: sticky;
  top: var(--#{$prefix}navbar-top-height);
  .nav {
    .nav-item {
      .nav-link {
        &.active {
          color: var(--#{$prefix}primary) !important;
          border-bottom: 1px solid var(--#{$prefix}primary) !important;
        }
      }
    }
  }
}

.fill-body-bg {
  fill: var(--#{$prefix}body-bg);
}
.fill-emphasis-bg {
  fill: var(--#{$prefix}emphasis-bg);
}

.text-stats-circle-success {
  color: var(--#{$prefix}success-bg-subtle) !important;
  [data-bs-theme='dark'] & {
    color: #134207 !important;
  }
}
.text-stats-circle-warning {
  color: var(--#{$prefix}warning-bg-subtle) !important;
  [data-bs-theme='dark'] & {
    color: #52310b !important;
  }
}
.text-stats-circle-danger {
  color: var(--#{$prefix}danger-bg-subtle) !important;
  [data-bs-theme='dark'] & {
    color: #612017 !important;
  }
}

.circle-icon-item {
  width: 48px;
  height: 48px;
  border: 1px solid var(--#{$prefix}primary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--#{$prefix}primary);
}

.btn-wish {
  width: 2rem;
  height: 2rem;
  color: var(--#{$prefix}white);
  border: 1px solid var(--#{$prefix}white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: var(--#{$prefix}white) !important;
    color: var(--#{$prefix}black) !important;
  }
  &.btn-wish-primary {
    color: var(--#{$prefix}primary);
    border: 1px solid var(--#{$prefix}primary);
    &:hover,
    &:focus,
    &.active {
      background-color: var(--#{$prefix}primary) !important;
      color: var(--#{$prefix}white) !important;
    }
  }
}

.rating-radio {
  + {
    label {
      padding: map-get($spacers, 3);
      border: 1px solid var(--#{$prefix}border-color-translucent) !important;
      .check-icon {
        display: none;
      }
    }
  }
  &:checked {
    + {
      label {
        border: 1px solid var(--#{$prefix}primary) !important;
        .check-icon {
          display: block;
        }
      }
    }
  }
}

.table-layout-fixed {
  table-layout: fixed;
}
.table-compare {
  --column-numbers: 3;
  --column-width: 250px;
  tbody {
    tr {
      width: calc(var(--column-numbers) * var(--column-width));
      display: flex;
      flex-wrap: wrap;
      td {
        &:first-child {
          flex: 0 0 100%;
          ~ td {
            flex: 1;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    tbody {
      tr {
        display: table-row;
        width: auto;
      }
    }
  }
}

// ping icon
.ping-icon-wrapper {
  position: relative;
  width: 10px;
  height: 10px;
  display: flex;
  .ping-icon-bg {
    position: absolute;
    background-color: var(--#{$prefix}success);
    display: inline-flex;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    opacity: 0.75;
    animation: animatePing 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .ping-icon {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-flex;
    color: var(--#{$prefix}success);
  }
}

@keyframes animatePing {
  75%,
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.rtl__flip {
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

.radio-circle {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--#{$prefix}border-color);
  border-radius: 50%;
  transition: border 0.2s ease;
}

.video-container {
  .video-icon {
    width: 2.375rem;
    height: 2.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(sm) {
      width: 3rem;
      height: 3rem;
    }
  }
  &:hover {
    .video-icon {
      display: none;
    }
  }
}

.tour-direction-bg {
  background-position: top 35% center !important;
  background-size: auto 80% !important;
  [dir='rtl'] & {
    transform: scaleY(-1);
    background-position: top 62% center !important;
  }
}

// password toggle
[data-password-toggle] {
  .hide {
    display: none;
  }
  &.show-password {
    .hide {
      display: block;
    }
    .show {
      display: none;
    }
  }
}

.btn-collapse {
  &[aria-expanded='true'] {
    .collapse-show {
      display: none;
    }
    .collapse-hide {
      display: inline-block;
    }
  }
  &[aria-expanded='false'] {
    .collapse-show {
      display: inline-block;
    }
    .collapse-hide {
      display: none;
    }
  }
}

.gallery-column-separator {
  position: absolute;
  height: 100%;
  padding: 0;
  width: auto;
  border-right: 1px solid var(--#{$prefix}border-color-translucent);
}

.gallery-column-separator-1 {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
    left: 50%;
  }
  @include media-breakpoint-up(md) {
    left: calc(100% / 3);
  }
  @include media-breakpoint-up(xl) {
    left: 25%;
  }
}
.gallery-column-separator-2 {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    left: calc((100% / 3) * 2);
  }
  @include media-breakpoint-up(xl) {
    left: 50%;
  }
}
.gallery-column-separator-3 {
  display: none;
  @include media-breakpoint-up(xl) {
    display: block;
    left: 75%;
  }
}

.album-item {
  .photo-stack {
    position: relative;
    .photo-stack-top {
      position: relative;
      width: 100%;
      z-index: 3;
      .video-container {
        video {
          @include media-breakpoint-up(md) {
            height: 160px !important;
          }
          @include media-breakpoint-up(xxl) {
            height: 260px !important;
          }
          object-fit: cover;
        }
      }
      img {
        @include media-breakpoint-up(md) {
          height: 160px;
        }
        @include media-breakpoint-up(xxl) {
          height: 260px;
        }
        transition: transform 0.5s ease;
      }
    }
    .photo-stack-middle {
      position: absolute;
      width: 90%;
      left: 50%;
      bottom: -(map-get($spacers, 2));
      transform: translateX(-50%);
      z-index: 2;
    }
    .photo-stack-bottom {
      position: absolute;
      width: 80%;
      left: 50%;
      bottom: -(map-get($spacers, 3));
      transform: translateX(-50%);
    }
  }
  &:hover {
    .top {
      img {
        transform: scale(1.2);
      }
    }
    .title {
      color: var(--#{$prefix}primary);
    }
    .dropdown-toggle {
      background-color: var(--#{$prefix}emphasis-bg);
    }
  }
}
